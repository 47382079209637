'use client';

import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

export default function NotFound() {
  const router = useRouter();

  useEffect(() => {
    const site = process.env.SITE || process.env.DEFAULT_SITE_ID;

    let redirectPath = '/error-SITE-missing'; // Default redirect path if none of the conditions match

    // Simplified logic: Redirect based on 'SITE' or 'DEFAULT_SITE_ID' variable
    if (site === 'WOWRES') {
      redirectPath = '/r3';
    }

    if (site === 'WOW') {
      redirectPath = '/r1';
    }

    // Uncomment below if you want to add conditions for specific pages in the future
    /*
    if (site === 'WOWRES' && !window.location.pathname.match(/r3|tv\/r3/)) {
      redirectPath = '/r3';
    }

    if (site === 'WOW' && !window.location.pathname.startsWith('/r1')) {
      redirectPath = '/r1';
    }
    */

    router.replace(redirectPath);
  }, [router]);

  return null; // Avoid rendering any content before redirection
}
